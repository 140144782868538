import arrayFrom from 'array-from';
import Parallax from 'parallax-js';

class Stage {
  constructor(options = {}) {
    this.element = options.element;
  }

  init() {
    console.log('Stage initititted', this.element);
    const parallaxInstance = new Parallax(this.element.querySelector('.mod--stage__vectors__wrapper'), {
      relativeInput: true,
    });
    // this.initParallax();
  }

  initParallax() {
    console.log('Init Parallax');
    const parallaxBox = this.element.querySelector('.mod--stage__vectors__wrapper');
    const strength = 0.2;
    let isMobile = false;

    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      isMobile = true;
    }

    function parallaxMove(parallaxContainer, x, y, boxWidth, boxHeight) {
      const layers = parallaxContainer.querySelectorAll('.vector');

      arrayFrom(layers).forEach((item) => {
        const depth = item.dataset.depth;
        const moveX = ((boxWidth / 2) - x) * (strength * depth);
        const moveY = ((boxHeight / 2) - y) * (strength * depth);

        item.firstElementChild.style.transform =  `translate(${moveX}%, ${moveY}%)`;
        //$(this).removeClass('is-out');
      });
    }


    function resetParallaxPosition (parallaxContainer) {
      const layers = parallaxContainer.querySelectorAll('.vector');

      arrayFrom(layers).forEach((item) => {
        item.style.transform =  'translate( 0, 0)';
        //$(this).removeClass('is-out');
      });
      event.stopPropagation();
    }

    if(!isMobile) {
      parallaxBox.addEventListener('mousemove', (event) => {
        event.stopPropagation();
        event = event || window.event;
        const x = Math.floor(event.clientX - parallaxBox.getBoundingClientRect().left);
        const y = Math.floor(event.clientY - parallaxBox.getBoundingClientRect().top);
        const boxWidth = parallaxBox.getBoundingClientRect().width;
        const boxHeight = parallaxBox.getBoundingClientRect().height;

        parallaxMove(parallaxBox, x, y, boxWidth, boxHeight);

      });
      // parallaxBox.mousemove(function(event) {
      //   event.stopPropagation();
      //   event = event || window.event;
      //   var x = Math.floor(event.clientX - $(this).offset().left),
      //   y = Math.floor(event.clientY - $(this).offset().top),
      //   boxWidth = $(this).width(),
      //   boxHeight = $(this).height();

      //   parallaxMove(this, x, y, boxWidth, boxHeight);

      // });

      parallaxBox.addEventListener('mouseleave', (event) => {
        if( event.target !== parallaxBox) {
          resetParallaxPosition(parallaxBox);
        }
      });

    } else if(isMobile) {
      const elem = document.getElementById("view3d");

      window.addEventListener('deviceorientation', (event) => {
        event.stopPropagation();
        event = event || window.event;

        const rotatedY =  Math.min(Math.max(parseInt(Math.floor(event.gamma)), -45), 45);
        const rotatedX = Math.min(Math.max(parseInt(Math.floor(event.beta)), -45), 45);
        const boxWidth = parallaxBox.getBoundingClientRect().width;
        const boxHeight = parallaxBox.getBoundingClientRect().height;

        const moveX = ((boxWidth/2) * rotatedY) / 45;
        const moveY = ((boxWidth/2) * rotatedX) / 45;

        parallaxMove(parallaxBox, moveX, moveY, boxWidth, boxHeight);

      });
    }
  }
}

export default Stage;
