import SmoothScroll from 'smooth-scroll';

import { hasClass, toggleClass } from '../../../../assets/javascript/tools/helpers';

const CLASSNAME_TOGGLE = 'main-navigation__toggle';
const CLASSNAME_OVERLAY = 'main-navigation__overlay';

class MainNavigation {
  constructor(options = {}) {
    this.element = options.element;
    this.toggle = this.element.querySelector(`.${CLASSNAME_TOGGLE}`);
    this.overlay = this.element.querySelector(`.${CLASSNAME_OVERLAY}`);
  }

  init() {
    this.toggle.addEventListener('click', () => {
      toggleClass(this.element, 'overlay-active');
      toggleClass(this.overlay, 'active');
      toggleClass(this.toggle, 'active');
    });

    this.overlay.addEventListener('click', () => {
      toggleClass(this.element, 'overlay-active');
      toggleClass(this.overlay, 'active');
      toggleClass(this.toggle, 'active');
    });

    this.applySmoothScroll();
  }

  applySmoothScroll() {
    // console.log('Applying Smooth Scroll');
    const smoothScroll = new SmoothScroll('a[href*="#"]', {
      speed: 500,
      offset: 100,
    });
  }
}

export default MainNavigation;
