import arrayFrom from 'array-from';
// import Waypoint from 'waypoints/lib/noframework.waypoints.min';

const iconInsert = (element, type) => {
  const template = `
    <i class="la la-lg la-${type}"></i>
  `;
  element.insertAdjacentHTML('afterbegin', template);
};

class RichText {
  constructor(options = {}) {
    this.element = options.element;
  }

  init() {
    // console.log('Rich Text Init');
    this.insertIcons();
    if (document.querySelector('.mod--rich-text') === this.element) {
      // console.log('Same Element');
      this.insertCross();
    }
  }

  insertIcons() {
    const anchors = this.element.querySelectorAll('a[href^="mailto:"]');
    // console.log(anchors);
    arrayFrom(anchors).forEach((anchor) => {
      iconInsert(anchor, 'envelope');
    });
  }

  insertCross() {
    const crossTemplate = `
      <div class="mod--rich-text__cross sticky">
        <svg xmlns="http://www.w3.org/2000/svg" width="210" height="210" viewBox="0 0 210 210">
          <path id="Pfad_13" d="M180.075 210L210 180.075v-4.357L34.283 0h-4.306L0 29.977v4.305L175.717 210z" style="fill:#b9d9eb" />
          <path id="Pfad_14" d="M34.283 210L210 34.283v-4.306L180.075 0h-4.357L0 175.717v4.357L29.977 210z" style="fill:#fbde41" />
        </svg>
      </div>
    `;
    this.element.insertAdjacentHTML('beforeend', crossTemplate);

    this.addAnimation();
  }

  addAnimation() {
    this.cross = this.element.querySelector('.mod--rich-text__cross');
    let scrollpos = window.scrollY;

    const addClassOnScroll = () => {
      this.cross.classList.add('sticky');
    };

    const removeClassOnScroll = () => {
      this.cross.classList.remove('sticky');
    };

    const offsetTop = this.element.offsetTop + (this.element.clientHeight / 2) - (window.innerHeight / 2);

    if (scrollpos >= offsetTop) {
      removeClassOnScroll();
    } else {
      addClassOnScroll();
    }

    window.addEventListener('scroll', () => {
      scrollpos = window.scrollY;
      const scrollOffsetTop = this.element.offsetTop + (this.element.clientHeight / 2) - (window.innerHeight / 2);

      if (scrollpos >= scrollOffsetTop) {
        removeClassOnScroll();
      } else {
        addClassOnScroll();
      }
    });
  }
}

export default RichText;
