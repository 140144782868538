import { addClass } from '../../../../../assets/javascript/tools/helpers';

class InstagramFeed {
  constructor(options = {}) {
    this.element = options.element;
    this.target = this.element.querySelector('.mod--instagram-feed__target');
  }

  init() {
    // eslint-disable-next-line no-undef
    const insta = new InstaFeed({
      username: 'ordentlich.in.schorle.werfen',
      container: this.target,
      display_profile: false,
      display_biography: false,
      display_gallery: true,
      callback: this.layout(),
      styling: false,
      items: 6,
    });
  }

  layout() {
    // addClass(this.target, 'grid');
  }
}

export default InstagramFeed;
